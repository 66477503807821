import { Injectable } from "@angular/core";
import iso31662 from './../../../../assets/json/iso-3166-2.json';
import { StateProvince } from "../models/location/state-province";
import { formatDate } from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class FormattingService {
    private stateProvinces: StateProvince[] = [];

    constructor() {
        this.loadStateProvinces();
    }

    private loadStateProvinces() {
        this.stateProvinces = [];

        for (let country of iso31662) {
            for (let stateProvince of country.divisions) {
                this.stateProvinces.push({
                    code: stateProvince.code,
                    name: stateProvince.name,
                    countryCode: country.code,
                    countryName: country.name
                });
            }
        }
    }

    formatStation(city: string, stateProvinceName: string, splc?: string, scac?: string) {
        let formattedCity = this.toTitleCase(city);
        let formattedStateProvinceCode = this.stateProvinces.find(x => x.name == stateProvinceName)?.code;

        if (!!splc && !!scac) {
            return `${formattedCity}, ${formattedStateProvinceCode} [${splc} - ${scac}]`;
        }
        else {
            return `${formattedCity}, ${formattedStateProvinceCode}`;
        }
    }
    
    toTitleCase(text: string) {
        return text.toLowerCase()
                   .split(' ')
                   .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                   .join(' ');
    }

    formatMonthNameYear(date: Date) {
        let fixedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
        return formatDate(fixedDate, 'LLL y', 'en-us', 'UTC');
    }
}